.App {
  text-align: center;
  font-family: bahnschrift;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.modal-content {
  word-wrap: break-word;
  overflow-wrap: break-word; 
  white-space: pre-wrap; 
}

.modal-content a {
  word-wrap: break-word; 
  overflow-wrap: break-word;
  color: #ff4e00;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.navbar{
  margin-right: "20px";
}


.homemain{
  background: url(../public/Assets/homee.gif);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding-top: 15%;
  padding-bottom: 15%;
}

.links{
  color: white;
}

.mainheading{
  font-size: 90px;
  color: white;
  font-weight: bolder;
}

.normalheading{
  font-size: 70px;
  color: white;
  font-weight: normal;
}

.paragraph{
  color: white;
}

.navbaricons{
  border:"2px solid white";
  border-radius:"50%";
  width:"40px";
  height:"40px";
  display:"flex";
  align-items:"center";
  justify-content:"center";
  margin-right:"10px";
}

.my-card {
  background-color: #1a2229 !important;
  padding: 10px;
}

.my-card:hover{
  background-color: #1b60f2 !important;
  color: white;
}

.register{
  background: url(../public/Assets/homee.gif);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.register-card{
  background-color: #1a2229 !important;
  max-width: 600px;
  width: 100%;
}

.inputs{
  border-radius: 0;
}


.head{
  padding: 70px;
}

.image-container {
  position: relative;
  width: 100%;
  height: 650px;
  background-image: url(../public/Assets/homeimg.png); 
  background-size: cover;
  background-position: center;
  box-shadow: 0 4px 8px rgba(0, 0, 1, 0.1);
  overflow: hidden; 
  margin-top: 3%;
}

.image-text h1{
  content: "";
  position: absolute;
  top: 45%; 
  left: 50%; 
  transform: translate(-50%, -50%); 
  text-align: center; 
  color: #ff4e00; 
  font-size: 50px;
  font-weight: 900; 
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  text-shadow: none !important;
}

.image-text p{
  content: "";
  position: absolute;
  top: 55%; 
  left: 50%; 
  transform: translate(-50%, -50%); 
  text-align: center; 
  color: black; 
  font-size: 20px;
  font-weight: 900; 
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  text-shadow: none !important;
}

.star-icon {
  width: 20px;
  height: 20px;
  margin-right: 5px;
  fill: gold;
}


@media screen and (max-width: 900px) {
  .image-text h1{
    top: 45%; 
    left: 50%; 
    font-size: 40px;
  }
  
  .image-text p{
    top: 56%; 
    font-size: 20px;
  }
}


@media screen and (max-width: 680px) {
  .image-text h1{
    top: 45%; 
    left: 50%; 
    font-size: 30px;
  }
  
  .image-text p{
    top: 68%; 
    font-size: 20px;
  }

  .image-container {
    height: 400px;
  }
}


.chat-container {
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.chat-header {
  text-align: center;
  margin-bottom: 20px;
}

.message-container {
  max-height: 300px;
  overflow-y: auto;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 8px;
}

.message {
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 8px;
}

.sent {
  background-color: #007bff;
  color: #fff;
  align-self: flex-end;
}

.received {
  background-color: #f8f9fa;
  color: #212529;
  align-self: flex-start;
}

.message-input {
  display: flex;
  align-items: center;
}

.message-input input {
  flex-grow: 1;
  padding: 10px;
  margin-right: 10px;
  border: 1px solid #ddd;
  border-radius: 8px;
  font-size: 16px;
}

.message-input button {
  padding: 10px 20px;
  border: none;
  border-radius: 8px;
  background-color: #007bff;
  color: #fff;
  cursor: pointer;
  font-size: 16px;
}

.detail-item {
  margin-bottom: 15px;
}

.detail-label {
  text-align: left;
  display: block;
  color: #ff4e00 !important;
  font-weight: bold;
}

.detail-value {
  color: white;
  margin-left: 10px;
}


.logomain{
  width: 150px ;
}
.logomain1{
  width: 230px;
}


.privacy{
  background-color: #1a2229 !important ;
  color: white !important;
  margin-top: 100px !important;
}

.buttonclose{
  background-color: #ff4e00 !important;
  color: white !important;
}

.anchor{
  color: #ff4e00 !important;
}


strong{
  color: #ff4e00 !important;

}

@media (max-width:500px)
{
  
.head{
  padding: 10px;
}


.mainheading{
  font-size: 30px;
}
.waveimage{
  height: 540px;
}

.decmargin{
  margin-left: 10px !important;
}
}

.h5{
  color: #ff4e00 ;
}

input::placeholder{
  color: white !important;
}

label{
  color: white !important;
}

.logoforgot{
  height: 200px !important;
}


input{
  color: white !important;
}

textarea{
  color: white !important;

}